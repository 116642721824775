import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  helper = inject(JwtHelperService)
  http = inject(HttpClient)
  private API_URL = environment.API_URL + "/auth"

  validate(token: string): Observable<any> {
    return this.http.get<any>(`${this.API_URL}/${token}`).pipe(
      tap(response => {
        if (response && response.access_token) {
          localStorage.setItem('access_token', response.access_token);
        }
      })
    );
  }

  authenticate(shopId: string): Observable<any> {
    return this.http.get<any>(`${this.API_URL}/shop/${shopId}`).pipe(
      tap(response => {
        if (response && response.access_token) {
          localStorage.setItem('access_token', response.access_token);
        }
      })
    );
  }

  private getClaims(): any {
    return this.helper.decodeToken(this.getAccessToken());
  }

  getTokenExpirationTime(): number | null {
    const token = this.getAccessToken();
    if (!token) {
      return null;
    }

    const date = this.helper.getTokenExpirationDate(token);
    const expiresIn = date.getTime() - new Date().getTime();

    return expiresIn;
  }

  getCompany() {
    return this.getClaims().company;
  }

  getShopId() {
    return this.getClaims().shopId;
  }

  getTableNumber() {
    return this.getClaims().tableNumber || null;
  }

  getAccessToken(): string | null {
    return localStorage.getItem('access_token');
  }

  resetToken() {
    localStorage.removeItem('access_token');
  }
}
